
/* eslint-disable */
import Vue from "vue";
import ChatService from "@/services/chat.service";
import HomeService from '@/services/home.service';
export default Vue.extend({
    name: 'encuesta',
    data() {
        return {
            claroOscuro:"",
            originBot:"",
            modal:true
        }
    },
    mounted() {
        this.cargaEstilos();
        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores);
        if(urlParams.has('guid')
            && urlParams.has('appType')
            && urlParams.has('channel')
            && urlParams.has('isBot')) 
        {
            var guid:any = String(urlParams.get('guid'));
            var appType:any = String(urlParams.get('appType'));
            var channel:any = String(urlParams.get('channel'));
            var isBot:any = String(urlParams.get('isBot'));
            ChatService.getCuestionario(isBot, guid, appType ,channel).then(resp => {
                var response = resp.data;
                localStorage.setItem('cuestionarioService-'+ guid, JSON.stringify(response));
                console.log('cuestionarioService-'+ guid);
                this.$router.push("/Chat/Chat/" + guid);
            }).catch(error=>{
                console.error("Ocurrio un error al obtener el cuestionario \n",error)
            });
        }
    },
    methods: {
        cargaEstilos(){
            HomeService.CargaInicial().then((resp) => {
                localStorage.setItem("claroOscuro", resp.data.claroOscuro);
                localStorage.setItem("originBot", resp.data.originBot);
                localStorage.setItem("pais", resp.data.pais);
            }).catch((error) =>{
                console.error("Error al realizar la carga inicial Encuesta",error);
            });
        }
    }

})
